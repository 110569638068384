import http from "./httpService";
import utilityService from "./utilityService";

const apiEndPointLogin = http.getApiEndPoint() + "user/login";
const apiEndPointRegister = http.getApiEndPoint() + "user/register";
const apiEndPointChangePassword =
  http.getApiEndPoint() + "user/change-password";
const localKey = "userinfo";
http.setToken(getToken());
export function login(user) {
  return http
    .post(apiEndPointLogin, {
      username: user.username,
      password: user.password,
    })
    .then((resp) => {
      let data = resp.data.data;
      setLocalStorageItem(data)
      // localStorage.setItem(localKey, JSON.stringify(data));
    });
}
export function setLocalStorageItem(data) {
  localStorage.setItem(localKey, JSON.stringify(data));
}
export function changePassword(postData) {
  return http.post(apiEndPointChangePassword, postData).then((resp) => {
    let data = resp.data.data;
    localStorage.setItem(localKey, JSON.stringify(data));
    return resp;
  });
}

export function register(user) {
  return http
    .post(apiEndPointRegister, {
      username: user.username,
      email: user.email,
      password: user.password,
    })
    .then((resp) => {
      let data = resp.data.data;
      localStorage.setItem(localKey, JSON.stringify(data));
    });
}
export function logout() {
  localStorage.removeItem(localKey);
}
export function currentuser() {
  let userinfo = localStorage.getItem(localKey);
  if (userinfo) {
    userinfo = JSON.parse(userinfo);
  }
  return userinfo;
}
export function getToken() {
  let userinfo = localStorage.getItem(localKey);
  if (userinfo) {
    userinfo = JSON.parse(userinfo).access_token;
  }
  return userinfo;
}

export function getCustomerId() {
  let userinfo = localStorage.getItem(localKey);
  let customerId = '';
  if (userinfo) {
    userinfo = JSON.parse(userinfo);
    customerId = utilityService.getSettingVal('Customerid',userinfo.settings)
  }
  return customerId;
}

export function getSiteSetting(code) {
  let userinfo = localStorage.getItem(localKey);
  if (userinfo) {
    let settings = JSON.parse(userinfo).settings.filter(
      (setting) => setting.settingcode === code
    );
    if (settings) {
      return settings.length ? settings[0].settingvalue : 0;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}
export function showMenuPermission(menu) {
  let userinfo = localStorage.getItem(localKey);
  userinfo = JSON.parse(userinfo);
  let allowPermission = false;
  if (userinfo.role.role_name == 'superadmin') {
    allowPermission = true;
  } else {
    allowPermission = userinfo.role.permission[menu];
  }
  return allowPermission;
}

export default {
  login,
  register,
  logout,
  currentuser,
  getToken,
  getSiteSetting,
  changePassword,
  showMenuPermission,
  setLocalStorageItem,
  getCustomerId,
};
