import LocalizedStrings from "react-localization";

export function languageString() {
  let strings = new LocalizedStrings({
    en: {
      privacyPoliciy: "Privacy policy",
      contactLegal: "Contact / Legal",
      username: "Username",
      password: "Password",
      login: "Login",
      forgotPassword: "Forgot Password",
      language: "Language",
      selectLanguage: "Select Language",
      fieldRequired: "Please provide {0}",
      fieldMin: "{0} should have at least {1} characters",
      fieldMax: "{0} should have at most {1} characters",
      submit: "Submit",
      pin: "PIN",
      confirmPassword: "Confirm Password",
      token: "Token",
      resetPassword: "Reset Password",
      workReport: "Work Report",
      timekeeping: "Timekeeping",
      jobList: "Job List",
      logout: "Logout",
      changePassword: "Change Password",
      home: "Home",
      activityReport: "Activity Report",
      addNewJob: "Add new Job",
      areaType: "Area",
      selectArea: "Select Area",
      customer: "Customer",
      selectCustomer: "Select Customer",
      order: "Order",
      selectOrder: "Select Order",
      activity: "Activity",
      selectActivity: "Select Activity",
      selectMachine: "Select Machine",
      checkJobIsDone: "Job is done",
      addJobToList: "Add Job to List",
      oldPassword: "Old Password",
      newPassword: "New Password",
      machine: "Machine",
      product: "Product",
      staff: "Employee",
      time: "Time",
      comment: "Comment",
      noJobListForToday: "No Job List for today",
      jobDetail: "Job details",
      success: "Done",
      jobListIsEmpty: "Job List is empty",
      start: "Start",
      stop: "Stop",
      pause: "Pause",
      resume: "Resume",
      date: "Date",
      status: "Status",
      latitude: "Latitude",
      longitude: "Longitude",
      notAllowGpsError:
        "Your browser does not support Geolocation. Please enable location service or GPS",
      notEnabledGpsError:
        "Geolocation is not enabled. Go to site settings and manually allow location access",
      gpsCordNotAvailable: "GPS cordinates not available",
      attendant: "Attendant",
      netTime: "Net time",
      showLog: "Show Log",
      shortList: "Shortlist",

      companyDetails: "Company Details",
      punctusLtd: "Punctus Ltd.",
      registeredOffice: "Registered Office",
      punctsAddressLine1: "483 Green Lanes",
      punctsAddressLine2: "London N13 4BS",
      punctsAddressLine3: "United Kingdom",
      vatNo: "VAT number",
      punctusVatNo: "GB 994 088 272",
      companyNo: "Company number",
      punctusCompanyNo: "6649069",
      registeredInCardiff: "Registered in Cardiff",
      phone: "Phone",
      email: "E-Mail",
      representedBy: "Represented by",
      punctusDirector: "Benjamin Lemme, Director",
      somethingGoesWrong: "An error has occurred.",

      present: "Present",
      actual: "Actual",
      targetTime: "Target Time",
      overTime: "Overtime",
      selectEmployee: "Select Employee",
      day: "Day",
      period: "Period",
      thisMonth: "This Month",
      lastMonth: "Last Month",
      go: "Go",
      hoursWorked: "Hours worked",
      total_present: "Total / Present",
      target: "Target",
      diff: "Diff.",
      selectedPeriod: "Selected Period",
      year: "Year",
      total: "Total",
      absence: "Absence",
      diary: "Calendar View",
      notStartedYet: "Not Started yet",
      started: "Started",
      paused: "Paused",
      stopped: "Stopped",
      resumed: "Resumed",
      currentStatus: "Current Status",
      presence: "Presence",
      notAllowEditJob: "Jobs in the past cannot be edited",
      notAllowAddJob: "Jobs cannot be added for the past",
      supplements: "Supplements",
      error: "Error",
      sumofJobDone: "Sum of jobs done",
      sumofOpenJob: "Sum of open Jobs",
      isShortList: "Shortlist",
      specialEffort: "Special Effort",
      pinFromEmail: "PIN from e-mail",
      pdf: "PDF",
      noProduct: "No Product",
      chooseImages: "Add photos",
      fileSizeisToobig: " file size is too big",
      unsupportedFileExtension: " is not a supported file extension",
      maxPhotosUploaded: "Max. {0} photos can be uploaded",
      errorMessageOnErrorPage:
        "This page cannot be loaded.Unfortunately, an error occurred.There may be no internet connection.",
      please: "Please",
      clickHere: "click here",
      reloadPage: "to reload the page or go to",
      httpNoresponseReceived:
        "Request cannot be executed. Potentially no internet connection available",
      httpErrorresponseReceived: "Unexpected error. Please try again",
      httpNoDataresponseReceived: "No data received. Please try again",
      sumOfJobConfirmedApp: "Sum of Jobs confirmed in App",
      sumOfJobNotConfirmedApp: "Sum of Jobs not confirmed in App",

      overview: "Overview",
      imetosDialyOverview: "iMETOS - Daily overview",
      noDataFound: "No Data Found",
      weatherAndCourse: "Weather and Course",
      air: "Air",
      min: "Min",
      max: "Max",
      average: "Average",
      sum: "Sum",
      soil: "Soil",
      temp: "Temp",
      rain: "Rain",
      weather: "Weather",
      wind: "Wind",
      course: "Course",
      jobListComment: "Job List Comment",
      tournaments: "Tournaments",
      toDoListAndReminder: "To do list and reminders",
      item: "Item",
      done: "Done",
      machineTypeIsRequired: "Machine Type is required",
      machineIsRequired: "Machine is required",
      operatingHoursIsRequired: "Operating Hours is required",
      productIsRequired: "Product is required",
      amountIsRequired: "Amount is required",
      dateisRequired: "Date is required",
      validationFailed: "Validation Failed",
      selectMachineType: "Select Machine Type",
     
      operatingHours: "Operating hours",
      lastFueling: "Last Fueling",
      updateOperatingHours: "Update Operating Hours",
      selectProduct: "Select Product",
      enterAmount: "Enter Amount",
      fuelingDate: "Fueling Date",
      subtractFromStaock: "Subtract from stock",
      hours: "Hours",
      qtyLtr: "Qty / liter",
      payOut: "Pay Out",
      paidOut: "Paid Out",
      overTimePaidoutInCurrentYear: "Overtime paid out in current year",
      machines: "Machines",
      name: "Name",
      noOption: "No Option",
      addFuelEntry: "Add Fuel Entry",
      on: "on",

      photobook: "Photobook",
      category: "Category",
      all: "All",
      editName: "Edit Name",
      rotate: "Rotate",
      cancel: "Cancel",
      update: "Update",
      edit: "Edit",
      deleteConfirmation: "Delete Confirmation",
      areYouSureYouWantToDeleteThisPhoto: "Are you sure you want to delete this photo?",
      chronological: "Chronological",
      alphabetical: "Alphabetical",
      ascending: "Ascending",
      descending: "Descending",
      delete: "Delete",
      notAllowedMessage: "You are not allowed to view this page.",
      soilSensor:"Soil Sensor",
      soilSensorMenu:"Soil Sensor Menu",
      sensorData:"Sensor Data",
      soilTemp:"Soil Temp",
      soilWater:"Soil Water",
      ec:"EC",
      electricalConductivity:"Electrical Conductivity",
      hours24:"24 hours",
      days2:"2 days",
      days7:"7 days",
      save:"Save",
      lastUpdate:"Last Update",
      vwc:"VWC",
      refresh:"Refresh",
      notifications:"Notifications",
      notificationSubscribe:"Subscribe Notification",
      noNotificationList:"Notification is empty",
      gis:"GIS",

      baseLayers:"Base layers",
      openStreetMap:"Open Street Map",
      topographicMap:"Topographic Map",
      satelliteImage:"Satellite Image",
      manageLayers:"Manage layers",
      latitudeLongitude:"latitude and longitude",
      search:"Search",
      clearReset:"Clear and reset",
      viewModel:"View 3D model",
      generalSettings:"General settings",
      initialCoordinatesForGIS:"Initial coordinates for GIS",
      editingOfPolygons:"Editing of Polygons",
      showOtherElementsPolygons :"Show other elements while editing the geometry of polygons ",
      addNewFeatureWithPen:"Add New Feature with Pen",
      whenAddNewFeatureDrawWithPen:"When add new feature draw with pen.",
      import:"Import",
      export:"Export",
      documentation:"Documentation",
      soilGuardData:"Soil Guard Data",
      uploadCSV:"Upload CSV",
      downloadCSV:"Download CSV",
      lastTwoDays:"Last Two Days",
      lastTwoWeeks:"Last Two Weeks",
      showFollowingValueOnTheMap:"Show following value on the map:",
      VWC:"VWC",
      volumetricWaterContent:"Volumetric Water Content",
      soilTemperature:"Soil Temperature",
      photoCategories:"Photo Categories ",
      areaSize:"Area Size",
      singleArea:"Single Area",
      areaByGroup:"Area By Group",
      properties:"Properties",
      manageLayer:"Manage Layer",
      close:"Close",
      viewImage:"View Image",
      photobook:"Photobook",
      confirmSelection:"Confirm Selection",
      updateDetails:"Update Details",
      category:"Category",
      select:"Select",
      title:"Title",
      description:"Description",
      yes:"Yes",
      no:"No",
      

      nightPremium:"Night Premium",
      activities:"Activities",

      //leave Request
      leaveRequest:"Leave Request",
      addRequest:"Add Request",
      leaveRequestForm:"Leave Request Form",
      reason:"Reason", 
      percent:"Percent", 
      leave:"Leave", 
      illness:"Illness", 
      select:"Select", 
      overtimeCompensation:"Overtime Compensation", 
      specialLeave:"Special leave", 
      unpaidAbsence:"Unpaid absence", 
      I:"I", 
      L:"L", 
      S:"S", 
      O:"O", 
      U:"U", 
      V:"V",
      i:"i",
      from:"From", 
      to:"To", 
      wholeDay:"Whole Day", 
      halfDay:"Half Day", 
      hours:"Hours", 
      ok:"Ok", 
      sureYouWantToDelete:"Are you sure you want to delete this leave request?",
      days:"Days",
      action:"Action",
      pending:"Pending",
      approved:"Approved",
      decline:"Decline",
      unknown:"Unknown",
      updateTable:"Update Table",
      selectTime:"Select Time",
      thisWeek:"This Week",
      lastWeek:"Last Week",
      thisMonth:"This Month",
      lastMonth:"Last Month",
      resetForm:"Reset Form",
    },
    de: {
      privacyPoliciy: "Datenschutz-Bestimmungen",
      contactLegal: "Kontakt / Impressum",
      username: "Nutzername",
      password: "Passwort",
      login: "Anmelden",
      forgotPassword: "Passwort vergessen",
      language: "Sprache",
      selectLanguage: "Sprache auswählen",
      fieldRequired: "{0} darf nicht leer sein",
      fieldMin: "{0} Sollte mindestens {1} Zeichen haben",
      fieldMax: "{0} Sollte höchstens {1} Zeichen haben",
      submit: "Bestätigen",
      pin: "PIN",
      confirmPassword: "Passwort bestätigen",
      token: "Token",
      resetPassword: "Passwort zurücksetzen",
      workReport: "Arbeitsbericht",
      timekeeping: "Zeiterfassung",
      jobList: "Arbeitsliste",
      logout: "Abmelden",
      changePassword: "Passwort ändern",
      home: "Startseite",
      activityReport: "Arbeitsbericht",
      addNewJob: "Neue Arbeit hinzufügen",
      areaType: "Ort / Fläche",
      selectArea: "Ort / Fläche auswählen",
      customer: "Kunde",
      selectCustomer: "Kunde auswählen",
      order: "Auftrag",
      selectOrder: "Auftrag auswählen",
      activity: "Aktivität",
      selectActivity: "Aktivität auswählen",
      selectMachine: "Maschine auswählen",
      checkJobIsDone: "Arbeit erledigt",
      addJobToList: "Arbeit zur Liste hinzufügen",
      oldPassword: "Altes Passwort",
      newPassword: "Neues Passwort",
      machine: "Maschinen",
      product: "Produkt",
      staff: "Mitarbeiter",
      time: "Zeit",
      comment: "Kommentar",
      noJobListForToday: "Keine Arbeiten für heute hinterlegt",
      jobDetail: "Arbeitsdetails",
      success: "Erledigt",
      jobListIsEmpty: "Arbeitsliste ohne Einträge",
      start: "Start",
      stop: "Stop",
      pause: "Pause",
      resume: "Fortsetzen",
      date: "Datum",
      status: "Status",
      latitude: "Breitengrad",
      longitude: "Längengrad",
      notAllowGpsError:
        "Ihr Browser unterstützt den Standortzugriff nicht. Aktivieren Sie bitte den Standortzugriff oder das GPS",
      notEnabledGpsError:
        "Der Standortzugriff ist nicht aktiviert. Rufen Sie die Einstellungen auf und erlauben Sie den Standortzugriff",
      gpsCordNotAvailable: "GPS-Koordinaten nicht verfügbar",
      attendant: "Anwesend",
      netTime: "Ist",
      showLog: "Protokoll anzeigen",
      shortList: "Kurze Tätigkeitsliste",
      companyDetails: "Angaben zur Firma",
      punctusLtd: "Punctus GmbH",
      registeredOffice: "Firmensitz",
      punctsAddressLine1: "Harleßstraße 5",
      punctsAddressLine2: "40239 Düsseldorf",
      punctsAddressLine3: "Deutschland",
      vatNo: "Umsatzsteuer-Identifikationsnummer",
      punctusVatNo: "DE 298 648 573",
      companyNo: "HRB",
      punctusCompanyNo: "89033",
      registeredInCardiff: "Eingetragen im Handelsregister Düsseldorf",
      phone: "Phone",
      email: "E-Mail",
      representedBy: "Vertreten durch",
      punctusDirector: "Benjamin Lemme Geschäftsführer",
      somethingGoesWrong: "Ein Fehler ist aufgetreten",

      present: "Anwesend",
      actual: "Ist",
      targetTime: "Soll",
      overTime: "Überstunden",
      selectEmployee: "Mitarbeiter auswählen",
      day: "Tag",
      period: "Zeitraum",
      thisMonth: "Aktueller Monat",
      lastMonth: "Letzter Monat",
      go: "Laden",
      hoursWorked: "Anwesenheit",
      total_present: "Anwesend",
      target: "Soll",
      diff: "Überstunden",
      selectedPeriod: "Gewählter Zeitraum",
      year: "Jahr",
      total: "Gesamt",
      absence: "Abwesenheit",
      diary: "Kalenderansicht",
      notStartedYet: "Nicht gestartet",
      started: "Gestartet",
      paused: "Pause",
      stopped: "Gestoppt",
      resumed: "Wieder gestartet",
      currentStatus: "Aktueller Status",
      presence: "Anwesend",
      notAllowEditJob:
        "Arbeiten in der Vergangenheit können nicht bearbeitet werden",
      notAllowAddJob:
        "Arbeiten in der Vergangenheit können nicht hinzugefügt werden",
      supplements: "Zuschläge",
      error: "Fehler",
      sumofJobDone: "Erledigte Arbeiten",
      sumofOpenJob: "Nicht erledigte Arbeiten",
      isShortList: "Kurze Tätigkeitsliste",
      specialEffort: "Sonderaufwand",
      pinFromEmail: "PIN aus der E-Mail",
      pdf: "PDF",
      noProduct: "Kein Produkt",
      chooseImages: "Fotos hinzufügen",
      fileSizeisToobig: " Dateigröße ist zu groß",
      unsupportedFileExtension: " ist keine unterstützte Dateierweiterung",
      maxPhotosUploaded: "Es können max. {0} Fotos hochgeladen werden",
      errorMessageOnErrorPage:
        "Diese Seite kann nicht geladen werden. Es ist leider ein Fehler aufgetreten. Möglicherweise besteht keine Internetverbindung.",
      please: "Bitte",
      clickHere: "klicken Sie hier",
      reloadPage: "um die Seite erneut aufzurufen oder gehen Sie zur",
      httpNoresponseReceived:
        "Aktion kann nicht ausgeführt werden. Möglicherweise keine Internetverbindung vorhanden",
      httpErrorresponseReceived:
        "Unerwarteter Fehler. Bitte versuchen Sie es erneut",
      httpNoDataresponseReceived:
        "Keine Daten erhalten. Bitte versuchen Sie es erneut",
      sumOfJobConfirmedApp: "Summe der in der App bestätigten Arbeiten",
      sumOfJobNotConfirmedApp: "Summe der in der App unbestätigten Arbeiten",

      overview: "Übersicht",
      imetosDialyOverview: "iMETOS - Tagesübersicht",
      noDataFound: "Keine Daten gefunden",
      weatherAndCourse: "Wetter und Platz",
      air: "Luft",
      min: "Min",
      max: "Max",
      average: "Durchschnitt",
      sum: "Summe",
      soil: "Boden",
      temp: "Temp",
      rain: "Regen",
      weather: "Wetter",
      wind: "Wind",
      course: "Platz",
      jobListComment: "Kommentar Arbeitsliste",
      tournaments: "Turniere",
      toDoListAndReminder: "Erinnerungen und Termine",
      item: "Sache",
      done: "Erledigt",
      date: "Datum",
      machineTypeIsRequired: "Maschinentyp ist erforderlich",
      machineIsRequired: "Maschine ist erforderlich",
      operatingHoursIsRequired: "Betriebsstunden erforderlich",
      productIsRequired: "Produkt ist erforderlich",
      amountIsRequired: "Betrag ist erforderlich",
      dateisRequired: "Datum ist erforderlich",
      validationFailed: "Überprüfung fehlgeschlagen",
      selectMachineType: "Maschinenart auswählen",
      selectMachine: "Maschine auswählen",
      operatingHours: "Betriebsstunden",
      lastFueling: "Letzte Betankung",
      updateOperatingHours: "Betriebsstunden aktualisieren",
      selectProduct: "Treibstoff",
      enterAmount: "Menge eingeben",
      fuelingDate: "Tankdatum",
      subtractFromStaock: "Vom Lagerbestand abziehen",
      hours: "Std",
      qtyLtr: "Menge / Liter",
      machines: "Maschinen",
      noOption: "Keine Auswahl",
      payOut: "Ausz.",
      paidOut: "Überstunden",
      overTimePaidoutInCurrentYear: "Ausbezahlung",
      name: "Name",
      addFuelEntry: "Betankung hinzufügen",
      on: "am",

      photobook: "Fotobuch",
      category: "Kategorie",
      all: "Alle",
      editName: "Name bearbeiten",
      rotate: "Rotieren",
      cancel: "Abbrechen",
      update: "Aktualisieren",
      edit: "Bearbeiten",
      deleteConfirmation: "Entfernen bestätigen",
      areYouSureYouWantToDeleteThisPhoto: "Möchten Sie das Foto entfernen?",
      chronological: "Chronologisch",
      alphabetical: "Alphabetisch",
      ascending: "Aufsteigend",
      descending: "Absteigend",
      delete: "Löschen",
      notAllowedMessage: "Sie sind nicht berechtigt, diese Seite anzuzeigen.",
      soilSensor:"Bodensensor",
      soilSensorMenu:"Soil Sensor Menu",
      sensorData:"Sensordaten",
      soilTemp:"Bodentemperatur",
      soilWater:"Bodenfeuchtigkeit",
      ec:"EC",
      electricalConductivity:"Elektrische Leitfähigkeit",
      hours24:"24 Stunden",
      days2:"2 Tage",
      days7:"7 Tage",
      save:"Speichern",
      lastUpdate:"Neueste Daten",
      vwc:"VWC",
      refresh:"Neu laden",
      notifications:"Benachrichtigungen",
      notificationSubscribe:"Subscribe Notification",
      noNotificationList:"Notification is empty",
      gis:"GIS",

      baseLayers:"Karte",
      openStreetMap:"Straßenkarte",
      topographicMap:"Topografische Karte",
      satelliteImage:"Satellitenbild",
      manageLayers:"Schichten verwalten",
      latitudeLongitude:"Breiten- und Längengrad",
      search:"Suche",
      clearReset:"Löschen und Zurücksetzen",
      viewModel:"3D-Modell",
      generalSettings:"Allgemeine Einstellungen",
      initialCoordinatesForGIS:"Initiale Koordinaten für das GIS",
      editingOfPolygons:"Bearbeitung von Polygonen",
      showOtherElementsPolygons :"Andere Elemente anzeigen, wenn die Form von Polygonen bearbeitet wird ",
      import:"Hinzufügen",
      documentation:"Dokumentation",
      soilGuardData:"Bodenwerte des Soil Guard",
      uploadCSV:"CSV-Datei hochladen",
      downloadCSV:"CSV-Datei herunterladen",
      lastTwoDays:"Letzte zwei Tage",
      lastTwoWeeks:"Letzte zwei Wochen",
      showFollowingValueOnTheMap:"Stelle folgenden Messwert auf der Karte dar:",
      VWC:"VWC",
      volumetricWaterContent:"Volumetrische Bodenfeuchtigkeit",
      soilTemperature:"Bodentemperatur",
      photoCategories:"Fotokategorien",
      areaSize:"Fläche",
      singleArea:"Einzelbereich",
      areaByGroup:"Bereich nach Gruppe",
      properties:"Eigenschaften",
      manageLayer:"Schichten verwalten",
      close:"Schließen",
      viewImage:"Bild anzeigen",
      photobook:"Fotobuch",
      confirmSelection:"Bestätigen",
      category:"Kategorie",
      title:"Titel",
      description:"Beschreibung",
      yes:"Ja",
      no:"Nein",
      
      nightPremium:"Nachtzuschlag",
      activities:"Tätigkeiten",
      pending:"Wartet",
      reason:"Grund", 
      percent:"Prozent", 
      leave:"Urlaub", 
      illness:"Krank", 
      select:"Auswahl", 
      overtimeCompensation:"Ausgleich von Überstunden", 
      specialLeave:"Sonderurlaub", 
      unpaidAbsence:"Sonstige Abwesenheit", 
      I:"K", 
      L:"U", 
      S:"S", 
      O:"Ü", 
      U:"A", 
      V:"U",
      i:"K",
      from:"von", 
      to:"bis", 
      wholeDay:"Ganzer Tag", 
      halfDay:"Halber Tag", 
      hours:"Stunden", 
      ok:"Ok", 
      days:"Tage",
      action:"Aktion",
      unknown:"Unbekannt",
      updateTable:"Tabelle neu laden",
      selectTime:"Wählen Sie Zeit",
      thisWeek:"Diese Woche",
      lastWeek:"Letzte Woche",
      thisMonth:"Dieser Monat",
      lastMonth:"Letzter Monat",
      resetForm:"Auswahl zurücksetzen",
      //leave Request
      leaveRequest:"Leave Request",
      addRequest:"Add Request",
      leaveRequestForm:"Leave Request Form",
      sureYouWantToDelete:"Are you sure you want to delete this leave request?",
      approved:"Approved",
      decline:"Decline",
      addNewFeatureWithPen:"Add New Feature with Pen",
      whenAddNewFeatureDrawWithPen:"When add new feature draw with pen.",
      export:"Export",
      updateDetails:"Update Details",
      select:"Select",

      
    },
    fe: {
      privacyPoliciy: "Politique de confidentialité",
      contactLegal: "Contact / Mentions légales",
      username: "Nom d'utilisateur",
      password: "Mot de passe",
      login: "Connexion",
      forgotPassword: "Mot de passe oublié",
      language: "Langue",
      selectLanguage: "Sélectionner la langue",
      fieldRequired: "{0} Ne doit pas être vide",
      fieldMin: "{0} Doit comporter au moins {1} caractères",
      fieldMax: "{0} Doit contenir au plus {1} caractères",
      submit: "Confirmer",
      pin: "PIN",
      confirmPassword: "Confirmer le mot de passe",
      token: "Token",
      resetPassword: "Réinitialiser le mot de passe",
      workReport: "Rapport de travail",
      timekeeping: "Enregistrement de l'heure",
      jobList: "Liste de travail",
      logout: "Déconnexion",
      changePassword: "Changer le mot de passe",
      home: "Page d'accueil",
      activityReport: "Rapport de travail",
      addNewJob: "Ajouter un nouveau travail",
      areaType: "Emplacement / Zone",
      selectArea: "Sélectionner un emplacement / une zone",
      customer: "Client",
      selectCustomer: "Sélectionner un client",
      order: "Ordre",
      selectOrder: "Sélectionner l'ordre",
      activity: "Activité",
      selectActivity: "Sélectionner une activité",
      selectMachine: "Sélectionner la machine",
      checkJobIsDone: "Travail terminé",
      addJobToList: "Ajouter du travail à la liste",
      oldPassword: "Ancien mot de passe",
      newPassword: "Nouveau mot de passe",
      machine: "Machine",
      product: "Produit",
      staff: "Employés",
      time: "Temps",
      comment: "Commenter",
      noJobListForToday: "Aucun travail publié pour aujourd'hui",
      jobDetail: "Détails du travail",
      success: "Terminé",
      jobListIsEmpty: "Liste de travail sans entrées",
      start: "Démarrer",
      stop: "Stop",
      pause: "Pause",
      resume: "Continuer",
      date: "Date",
      status: "Statut",
      latitude: "Latitude",
      longitude: "Longitude",
      notAllowGpsError:
        "Votre navigateur ne prend pas en charge l'accès à la localisation. Veuillez activer l'accès à la localisation ou le GPS",
      notEnabledGpsError:
        "L'accès à la position n'est pas activé. Accédez aux paramètres et autorisez l'accès à la position",
      gpsCordNotAvailable: "Coordonnées non disponibles",
      attendant: "Présent",
      netTime: "Est",
      showLog: "Afficher le journal",
      shortList: "Liste restreinte d'activités",
      companyDetails: "Coordonnées de l'entreprise",
      punctusLtd: "Punctus GmbH",
      registeredOffice: "Siège social",
      punctsAddressLine1: "Harlessstrasse 5",
      punctsAddressLine2: "40239 Duesseldorf",
      punctsAddressLine3: "Allemagne",
      vatNo: "Numéro d'identification TVA",
      punctusVatNo: "DE 298 648 573",
      companyNo: "HRB",
      punctusCompanyNo: "89033",
      registeredInCardiff:
        "Enregistré au registre du commerce de Duesseldorf Allemagne",
      phone: "Téléphone",
      email: "Email",
      representedBy: "Représenté par",
      punctusDirector: "Benjamin Lemme directeur général",
      somethingGoesWrong: "Une erreur s'est produite",

      present: "Présent",
      actual: "Est",
      targetTime: "Cible",
      overTime: "Diff.",
      selectEmployee: "Sélectionner un employé",
      day: "Jour",
      period: "Période",
      thisMonth: "Mois en cours",
      lastMonth: "Le mois dernier",
      go: "Confirmer",
      hoursWorked: "Présence",
      total_present: "Présent",
      target: "Cible",
      diff: "Diff.",
      selectedPeriod: "Période sélectionnée",
      year: "Année",
      total: "Total",
      absence: "Absence",
      diary: "Vue calendrier",
      notStartedYet: "Non démarré",
      started: "Démarré",
      paused: "Pause",
      stopped: "Arrêté",
      resumed: "Redémarré",
      currentStatus: "Statut actuel",
      presence: "Présent",
      notAllowEditJob: "Les travaux du passé ne peuvent pas être modifiés",
      notAllowAddJob: "Les travaux antérieurs ne peuvent pas être ajoutés",
      supplements: "Suppléments",
      error: "Erreur",
      sumofJobDone: "Total du travail effectué",
      sumofOpenJob: "Total des travaux non effectués",
      isShortList: "Liste restreinte d'activités",
      specialEffort: "Dépense spéciale",
      pinFromEmail: "PIN de l'e-mail",
      pdf: "PDF",
      noProduct: "Aucun produit",
      chooseImages: "Choisissez des images",
      fileSizeisToobig: " la taille du fichier est trop grande",
      unsupportedFileExtension:
        " n'est pas une extension de fichier prise en charge",
      errorMessageOnErrorPage:
        "Impossible de charger cette page. Malheureusement, une erreur s'est produite. Il se peut qu'il n'y ait pas de connexion Internet.",
      please: "S'il vous plaît",
      clickHere: "cliquez ici",
      reloadPage: "pour recharger la page ou aller à",
      httpNoresponseReceived: "Request cannot be executed",
      httpErrorresponseReceived: "Unexpected error occured",
      httpNoDataresponseReceived: "No data reeeived",
      httpNoresponseReceived:
        "La demande ne peut pas être exécutée. Potentiellement pas de connexion Internet disponible",
      httpErrorresponseReceived: "Erreur inattendue. Veuillez réessayer",
      httpNoDataresponseReceived: "Aucune donnée recue. Veuillez réessayer",
      sumOfJobConfirmedApp: "Somme des emplois confirmée dans l'application",
      sumOfJobNotConfirmedApp:
        "Somme des travaux non confirmée dans l'application",

      overview: " Aperçu",
      imetosDialyOverview: "iMETOS - Aperçu quotidien",
      noDataFound: " Aucune donnée disponible",
      weatherAndCourse: " Météo et parcours",
      air: "Air",
      min: "Min",
      max: "Max",
      average: " Moyen",
      sum: "Somme",
      soil: "Sol",
      temp: "Temp",
      rain: " Pluie",
      weather: "Temps",
      wind: "Vent",
      course: "Cours",
      jobListComment: " Commentaire de la liste des tâches",
      tournaments: " Tournois",
      toDoListAndReminder: " Liste de tâches et rappels",
      item: " Article",
      done: "Fait",
      date: "Date",
      machineTypeIsRequired: "Le type de machine est requis",
      machineIsRequired: " La machine est requise",
      operatingHoursIsRequired: " Heures d'ouverture est requis",
      productIsRequired: " Le produit est requis",
      amountIsRequired: " Le montant est requis",
      dateisRequired: " La date est requise",
      validationFailed: " Validation échouée",
      selectMachineType: " Sélectionnez le type de machine",
      selectMachine: " Sélectionnez l'appareil",
      operatingHours: " Heures d'ouverture",
      lastFueling: " Dernier ravitaillement",
      updateOperatingHours: " Dernier ravitaillement",
      selectProduct: " Sélectionner un produit",
      enterAmount: " Entrer le montant",
      fuelingDate: " Date de ravitaillement",
      subtractFromStaock: " Soustraire du stock",
      hours: " Heures",
      qtyLtr: " Qté / litre",
      soilSensor:"Soil Sensor",
      soilSensorMenu:"Soil Sensor Menu",
      sensorData:"Sensor Data",
      soilTemp:"La température du sol",
      soilWater:"Soil Water",
      ec:"EC",
      electricalConductivity:"Electrical Conductivity",
      hours24:"24 heures",
      days2:"2 heures",
      days7:"7 heures",
      save:"Sauver",
      lastUpdate:"Last Update",
      vwc:"VWC",
      refresh:"Recharger",
      notifications:"Benachrichtigungen",
      notificationSubscribe:"Subscribe Notification",
      noNotificationList:"Notification is empty",
      gis:"GIS",

      baseLayers:"Base layers",
      openStreetMap:"Open Street Map",
      topographicMap:"Topographic Map",
      satelliteImage:"Satellite Image",
      manageLayers:"Manage layers",
      latitudeLongitude:"latitude and longitude",
      search:"Search",
      clearReset:"Clear and reset",
      viewModel:"View 3D model",
      generalSettings:"General settings",
      initialCoordinatesForGIS:"Initial coordinates for GIS",
      editingOfPolygons:"Editing of Polygons",
      showOtherElementsPolygons :"Show other elements while editing the geometry of polygons ",
      addNewFeatureWithPen:"Add New Feature with Pen",
      whenAddNewFeatureDrawWithPen:"When add new feature draw with pen.",
      import:"Import",
      export:"Export",
      documentation:"Documentation",
      soilGuardData:"Soil Guard Data",
      uploadCSV:"Upload CSV",
      downloadCSV:"Download CSV",
      lastTwoDays:"Last Two Days",
      lastTwoWeeks:"Last Two Weeks",
      showFollowingValueOnTheMap:"Show following value on the map:",
      VWC:"VWC",
      volumetricWaterContent:"Volumetric Water Content",
      soilTemperature:"Soil Temperature",
      photoCategories:"Photo Categories ",
      areaSize:"Area Size",
      singleArea:"Single Area",
      areaByGroup:"Area By Group",
      properties:"Properties",
      manageLayer:"Manage Layer",
      close:"Close",
      viewImage:"View Image",
      photobook:"Photobook",
      confirmSelection:"Confirm Selection",
      updateDetails:"Update Details",
      category:"Category",
      select:"Select",
      title:"Title",
      description:"Description",
      yes:"Yes",
      no:"No",


      activities:"Activités",
      reason:"Raison", 
      percent:"Pour cent", 
      leave:"Vacances", 
      illness:"Maladie", 
      select:"Sélection", 
      overtimeCompensation:"Rémunération des heures supplémentaires", 
      specialLeave:"Congé spécial", 
      unpaidAbsence:"Absence non rémunérée", 
      I:"M", 
      L:"V", 
      S:"VS", 
      O:"RS", 
      U:"A", 
      V:"V",
      i:"M",
      from:"De", 
      to:"à", 
      wholeDay:"Toute la journée", 
      halfDay:"Une demi-journée", 
      hours:"Heures", 
      ok:"Valider", 
      days:"Jours",
      action:"changements",
      pending:"Attendre",
      unknown:"Inconnue",
      updateTable:"Recharger la table",
      thisWeek:"Cette semaine",
      lastWeek:"La semaine dernière",
      thisMonth:"Ce mois-ci",
      lastMonth:"Le mois dernier",
      resetForm:"Réinitialiser le formulaire",
      
      //leave Request
      selectTime:"Select Time",
      addRequest:"Add Request",
      leaveRequest:"Leave Request",
      leaveRequestForm:"Leave Request Form",
      sureYouWantToDelete:"Are you sure you want to delete this leave request?",
      nightPremium:"Night Premium",
      approved:"Approved",
      decline:"Decline",

    },
    ru: {
      privacyPoliciy: "Политика конфиденциальности",
      contactLegal: "Контакт / Выходные данные",
      username: "имя пользователя",
      password: "Пароль",
      login: "Логин",
      forgotPassword: "Забыли пароль",
      language: "язык",
      selectLanguage: "Выбрать язык",
      fieldRequired: "{0} не может быть пустым",
      fieldMin: "{0} должно содержать не менее {1} символов",
      fieldMax: "{0} должно содержать не более {1} символов",
      submit: "Подтвердить",
      pin: "PIN",
      confirmPassword: "Подтвердить пароль",
      token: "Токен",
      resetPassword: "сбросить пароль",
      workReport: "Отчет о работе",
      timekeeping: "хронометраж",
      jobList: "список работ",
      logout: "Выйти",
      changePassword: "Сменить пароль",
      home: "Дом",
      activityReport: "отчет о работе",
      addNewJob: "Добавить новую вакансию",
      areaType: "Местоположение / Площадь",
      selectArea: "Выберите местоположение / область",
      customer: "заказчик",
      selectCustomer: "Выбрать клиента",
      order: "заказ",
      selectOrder: "Выбрать заказ",
      activity: "активность",
      selectActivity: "Выбрать действие",
      selectMachine: "Выбрать машину",
      checkJobIsDone: "Работа выполнена",
      addJobToList: "добавить работу в список",
      oldPassword: "Старый пароль",
      newPassword: "Новый пароль",
      machine: "машина",
      product: "продукт",
      staff: "сотрудники",
      time: "время",
      comment: "Комментарий",
      noJobListForToday: "На сегодня вакансий нет",
      jobDetail: "детали работы",
      success: "выполненный",
      jobListIsEmpty: "Список работ без записей",
      start: "Старт",
      stop: "Стоп",
      pause: "пауза",
      resume: "продолжить",
      date: "дата",
      status: "Статус",
      latitude: "широта",
      longitude: "долгота",
      notAllowGpsError:
        "Ваш браузер не поддерживает доступ к местоположению. Пожалуйста, активируйте доступ к местоположению или GPS",
      notEnabledGpsError:
        "Доступ к местоположению не включен. Зайдите в настройки и разрешите доступ к местоположению",
      gpsCordNotAvailable: "Координаты GPS недоступны",
      attendant: "присутствует",
      netTime: "Является",
      showLog: "Показать журнал",
      shortList: "Краткий список мероприятий",

      companyDetails: "Реквизиты компании",
      punctusLtd: "Punctus GmbH",
      registeredOffice: "Штаб-квартира",
      punctsAddressLine1: "Harlessstrasse 5",
      punctsAddressLine2: "40239 Дюссельдорф",
      punctsAddressLine3: "Германия",
      vatNo: "Идентификационный номер плательщика НДС",
      punctusVatNo: "DE 298 648 573",
      companyNo: "HRB",
      punctusCompanyNo: "89033",
      registeredInCardiff:
        "Зарегистрировано в торговом реестре Дюссельдорфа Германия",
      phone: "Телефон",
      email: "Эл. почта",
      representedBy: "Представлена",
      punctusDirector: "Бенджамин Лемм управляющий директор",
      somethingGoesWrong: "Произошла ошибка",

      present: "настоящее время",
      actual: "Есть",
      targetTime: "цель",
      overTime: "разн.",
      selectEmployee: "Выбрать сотрудника",
      day: "день",
      period: "период",
      thisMonth: "Текущий месяц",
      lastMonth: "Прошлый месяц",
      go: "Загрузить",
      hoursWorked: "Посещаемость",
      total_present: "присутствующий",
      target: "цель",
      diff: "разн.",
      selectedPeriod: "Выбранный период",
      year: "год",
      total: "всего",
      absence: "отсутствие",
      diary: "Просмотр календаря",
      notStartedYet: "Не запущено",
      started: "Начато",
      paused: "Перерыв",
      stopped: "Остановился",
      resumed: "Началось снова",
      currentStatus: "Нынешнее состояние",
      presence: "настоящее время",
      notAllowEditJob: "Текущий статус",
      notAllowAddJob: "настоящее",
      supplements: "надбавка",
      error: "ошибка",
      sumofJobDone: "Всего проделано",
      sumofOpenJob: "Общая работа не выполнена",
      isShortList: "Is shortlistКраткий список мероприятий",
      specialEffort: "Специальный расход",
      pinFromEmail: "PIN-код из электронного письма",
      pdf: "PDF",
      noProduct: "Нет продукта",
      chooseImages: "Выбрать изображения",
      fileSizeisToobig: " размер файла слишком большой",
      unsupportedFileExtension: " не поддерживается расширение файла",
      errorMessageOnErrorPage:
        "Эта страница не может быть загружена. К сожалению, произошла ошибка. Возможно, нет подключения к Интернету.",
      please: "пожалуйста",
      clickHere: "кликните сюда",
      reloadPage: "перезагрузить страницу или перейти на",
      httpNoresponseReceived: "Request cannot be executed",
      httpErrorresponseReceived: "Unexpected error occured",
      httpNoDataresponseReceived: "No data reeeived",
      httpNoresponseReceived:
        "Запрос не может быть выполнен. Возможно, нет подключения к Интернету",
      httpErrorresponseReceived:
        "Непредвиденная ошибка. Пожалуйста, попробуйте еще раз",
      httpNoDataresponseReceived: "Нет данных. Пожалуйста, попробуйте еще раз",
      sumOfJobConfirmedApp: "Сумма заданий подтверждена в приложении",
      sumOfJobNotConfirmedApp: "Сумма вакансий не подтверждена в приложении",

      overview: "Overview",
      imetosDialyOverview: "iMETOS - Daily overview",
      noDataFound: "No Data Found",
      weatherAndCourse: "Weather and Course",
      air: "Air",
      min: "Min",
      max: "Max",
      average: "Average",
      sum: "Sum",
      soil: "Soil",
      temp: "Temp",
      rain: "Rain",
      weather: "Weather",
      wind: "Wind",
      course: "Course",
      jobListComment: "Job List Comment",
      tournaments: "Tournaments",
      toDoListAndReminder: "To do list and reminders",
      item: "Item",
      done: "Done",
      date: "Date",
      machineTypeIsRequired: "Machine Type is required",
      machineIsRequired: "Machine is required",
      operatingHoursIsRequired: "Operating Hours is required",
      productIsRequired: "Product is required",
      amountIsRequired: "Amount is required",
      dateisRequired: "Date is required",
      validationFailed: "Validation Failed",
      selectMachineType: "Select Machine Type",
      selectMachine: "Select Machine",
      operatingHours: "Operating hours",
      lastFueling: "Last Fueling",
      updateOperatingHours: "Update Operating Hours",
      selectProduct: "Select Product",
      enterAmount: "Enter Amount",
      fuelingDate: "Fueling Date",
      subtractFromStaock: "Subtract from stock",
      hours: "часов",
      qtyLtr: "Qty / liter",
      soilSensor:"Soil Sensor",
      sensorData:"Sensor Data",
      soilSensorMenu:"Soil Sensor Menu",
      soilTemp:"Температура почвы",
      soilWater:"Soil Water",
      ec:"EC",
      electricalConductivity:"Electrical Conductivity",
      hours24:"24 часы",
      days2:"2 дни",
      days7:"7 дни",
      save:"Сохранить",
      lastUpdate:"Last Update",
      vwc:"VWC",
      refresh:"Обновить",
      notifications:"Benachrichtigungen",
      notificationSubscribe:"Subscribe Notification",
      noNotificationList:"Notification is empty",
      gis:"GIS",
      activities:"Деятельность",

      baseLayers:"Base layers",
      openStreetMap:"Open Street Map",
      topographicMap:"Topographic Map",
      satelliteImage:"Satellite Image",
      manageLayers:"Manage layers",
      latitudeLongitude:"latitude and longitude",
      search:"Search",
      clearReset:"Clear and reset",
      viewModel:"View 3D model",
      generalSettings:"General settings",
      initialCoordinatesForGIS:"Initial coordinates for GIS",
      editingOfPolygons:"Editing of Polygons",
      showOtherElementsPolygons :"Show other elements while editing the geometry of polygons ",
      addNewFeatureWithPen:"Add New Feature with Pen",
      whenAddNewFeatureDrawWithPen:"When add new feature draw with pen.",
      import:"Import",
      export:"Export",
      documentation:"Documentation",
      soilGuardData:"Soil Guard Data",
      uploadCSV:"Upload CSV",
      downloadCSV:"Download CSV",
      lastTwoDays:"Last Two Days",
      lastTwoWeeks:"Last Two Weeks",
      showFollowingValueOnTheMap:"Show following value on the map:",
      VWC:"VWC",
      volumetricWaterContent:"Volumetric Water Content",
      soilTemperature:"Soil Temperature",
      photoCategories:"Photo Categories ",
      areaSize:"Area Size",
      singleArea:"Single Area",
      areaByGroup:"Area By Group",
      properties:"Properties",
      manageLayer:"Manage Layer",
      close:"Close",
      viewImage:"View Image",
      photobook:"Photobook",
      confirmSelection:"Confirm Selection",
      updateDetails:"Update Details",
      category:"Category",
      select:"Select",
      title:"Title",
      description:"Description",
      yes:"Yes",
      no:"No",

      reason:"Причина", 
      percent:"Процент", 
      leave:"Отпуск", 
      illness:"Болезнь", 
      select:"Выбор", 
      overtimeCompensation:"Оплата сверхурочных", 
      specialLeave:"Специальный отпуск", 
      unpaidAbsence:"Неоплачиваемое отсутствие", 
      I:"Б", 
      L:"О", 
      S:"С", 
      O:"Св", 
      U:"НО", 
      V:"О",
      i:"б",
      from:"с", 
      to:"до", 
      wholeDay:"Целый день", 
      halfDay:"Полдня", 
      hours:"часов", 
      ok:"OK", 
      days:"Дни",
      action:"Действие",
      pending:"На рассмотрении",
      unknown:"неизвестный",
      updateTable:"Обновить таблицу",
      thisWeek:"Эта неделя",
      lastWeek:"Последняя неделя",
      thisMonth:"Этот месяц",
      lastMonth:"Последний месяц",
      selectTime:"Select Time",
      resetForm:"Reset Form",

       //leave Request
      addRequest:"Add Request",
      leaveRequest:"Leave Request",
      leaveRequestForm:"Leave Request Form",
      sureYouWantToDelete:"Are you sure you want to delete this leave request?",
      nightPremium:"Night Premium",
      approved:"Approved",
      decline:"Decline",
    },
  });
  strings.setLanguage(getCurrentLanguage());
  return strings;
}

const LanguageStorageKey = "LanguageStorageKey";
const DefaultLanguage = "en";

export function saveLanguageSelection(language) {
  localStorage.setItem(LanguageStorageKey, language);
}

export function getCurrentLanguage() {
  let language = localStorage.getItem(LanguageStorageKey);
  if (language) {
    return language;
  }
  return DefaultLanguage;
}

export let lacalize = languageString();

export function LanguageList() {
  return [
    { key: "en", value: "English" },
    { key: "de", value: "Deutsch" },
    { key: "fe", value: "Français" },
    { key: "ru", value: "Russian" },
  ];
}

export default {
  DefaultLanguage,
  saveLanguageSelection,
  getCurrentLanguage,
  LanguageList,
  languageString,
  lacalize,
};
