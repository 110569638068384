import axios from "axios";
import { toast } from "react-toastify";
import languageService, { languageString as lacalize } from "./languageService";
import { ApiBaseUrl } from "../config.json";
import { getCustomerId } from "./authService";


axios.interceptors.response.use(
  (suucess) => {
    hideLoader();
    return suucess;
  },
  (error) => {
    hideLoader();
    if (error.response.status == 403) {
      window.location.href = "/not-allowed";
      //const history = useHistory();
      //history.replace("/not-allowed");
      return Promise.reject(error);
    }
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status <= 500;
    if (!error.response) {
      //console.log(lacalize().httpNoresponseReceived, error);
      //toast.error(lacalize().httpNoresponseReceived);
      return new Promise(() => { Promise.reject(error); });
    } else if (!error.response.data) {
      //console.log(lacalize().httpNoDataresponseReceived, error);
      toast.error(lacalize().httpNoDataresponseReceived);
      return new Promise(() => { Promise.reject(error); });
    }
    if (!expectedError) {
      //console.log(lacalize().httpErrorresponseReceived, error);
      toast.error(lacalize().httpErrorresponseReceived);
    } else if (error.response.status === 500) {
      toast.error(error.response.data.data.message);
      //console.log(lacalize().httpErrorresponseReceived, error);
      return new Promise(() => { Promise.reject(error); });
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use((req) => {
  showLoader();
  req.headers["Accept-Language"] = languageService.getCurrentLanguage();
  return req;
});
// axios.defaults.headers.common[
//   "Accept-Language"
// ] = languageService.getCurrentLanguage();

export function setToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  let customerId = getCustomerId();
  axios.defaults.headers.common["customer-id"] = customerId;
}

export function showLoader() {
  let loader = document.querySelector(".main-root-loader");
  if (loader) {
    loader.classList.remove("collapse");
  }
  let container = document.querySelector(".main-container-root");
  if (container) {
    container.classList.add("collapse");
  }
}

export function hideLoader() {
  let loader = document.querySelector(".main-root-loader");
  if (loader) {
    loader.classList.add("collapse");
  }
  let container = document.querySelector(".main-container-root");
  if (container) {
    container.classList.remove("collapse");
  }
}

export function getApiEndPoint() {
  let host = window.location.host;
  let apiEndPoint = ApiBaseUrl;

  //return apiEndPoint;
  if (host.indexOf("m.") !== -1) {
    apiEndPoint = window.location.origin;
    apiEndPoint = removeFirstOccurrence(apiEndPoint, "m.");
    apiEndPoint = apiEndPoint + `/api/v1/`;
  }

  if (host.indexOf("m-") !== -1) {
    apiEndPoint = window.location.origin;
    apiEndPoint = removeFirstOccurrence(apiEndPoint, "m-");
    apiEndPoint = apiEndPoint + `/api/v1/`;
  }

  if (host.indexOf("-mobil") !== -1) {
    apiEndPoint = window.location.origin;
    apiEndPoint = removeFirstOccurrence(apiEndPoint, "-mobil");
    apiEndPoint = apiEndPoint + `/api/v1/`;
  }
  //return 'https://demo-golf-de.punctus.com/api/v1/';
  return apiEndPoint;
}

export function getAdvancedApiEndPoint() {
  let apiEndPoint = 'https://api.punctus.com/v1/';
  let ApiBaseUrlEnd = getApiEndPoint();
  if(ApiBaseUrlEnd.includes('.local')){
    apiEndPoint = 'http://www.api.punctus.local/v1/'
  }
  return apiEndPoint;
}
export function removeFirstOccurrence(str, searchstr) {
  var index = str.indexOf(searchstr);
  if (index === -1) {
    return str;
  }
  return str.slice(0, index) + str.slice(index + searchstr.length);
}
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  options: axios.options,
  setToken,
  getApiEndPoint,
  getAdvancedApiEndPoint,
  removeFirstOccurrence,
  showLoader,
  hideLoader,
};
