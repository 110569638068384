import languageService from "./languageService";

export function getPrivacyPolicyLink() {
  let language = languageService.getCurrentLanguage();
  let link = "#";
  if (language == "ru") {
    link =
      "https://punctus.com/downloads/Punctus%20Data%20Protection%20Policy.pdf";
  } else if (language == "de") {
    link = "https://punctus.com/downloads/Punctus%20Datenschutz.pdf";
  } else if (language == "fe") {
    link =
      "https://punctus.com/downloads/Punctus%20Politique%20de%20protection%20des%20donn%c3%a9es.pdf";
  } else {
    link =
      "https://punctus.com/downloads/Punctus%20Data%20Protection%20Policy.pdf";
  }
  return link;
}

export function getContactLegalLink() {
  let language = languageService.getCurrentLanguage();
  let link = "#";
  if (language == "ru") {
    link = "https://www.punctus.com/eng/legal.html";
  } else if (language == "de") {
    link = "https://www.punctus.com/ger/legal.html";
  } else if (language == "fe") {
    link = "https://www.punctus.com/fra/legal.html";
  } else {
    link = "https://www.punctus.com/eng/legal.html";
  }
  return link;
}

export function goBack(props) {
  if (props.history) {
    props.history.goBack();
  } else {
    console.log("failed");
  }
}

export function getSettingVal(code, settings) {
  if (settings) {
    let settingVal = settings.filter((setting) => setting.settingcode === code);
    if (settingVal) {
      return settingVal.length ? settingVal[0].settingvalue : 0;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export default {
  getPrivacyPolicyLink,
  getContactLegalLink,
  goBack,
  getSettingVal,
};
