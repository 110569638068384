import React, { Component, Suspense, lazy } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import ProtectedRoute from "./components/common/protectedRoute";
import { ToastContainer } from "react-toastify";
import { HashRouter, Route, Switch } from "react-router-dom";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Login = React.lazy(() => import("./components/login"));
const ForgotPassWord = React.lazy(() => import("./components/forgotPassWord"));
const ResetPassWord = React.lazy(() => import("./components/resetPassWord"));
const Main = React.lazy(() => import("./components/layout/main"));
const Legal = React.lazy(() => import("./components/legal"));
class App extends Component {
  render() {
    return (
      <React.Fragment>
        <ToastContainer />

        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/legal"
              name="Legal Page"
              render={(props) => <Legal {...props} />}
            />

            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassWord {...props} />}
            />
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              render={(props) => <ResetPassWord {...props} />}
            />

            <ProtectedRoute
              path="/"
              name="Main Layout"
              render={(props) => <Main {...props} />}
            />
            <ProtectedRoute
              path="/dashboard"
              name="Main Layout"
              render={(props) => <Main {...props} />}
            />
          </Switch>
        </React.Suspense>
      </React.Fragment>
    );
  }
}

export default App;
