import React from "react";
import auth, { showMenuPermission } from "../../services/authService";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
  var pathMapObject = {
    '/overview': 'appOverview',
    '/joblist/:date?': 'appJobList',
    '/joblist/clear': 'appJobList',
    '/joblist': 'appJobList',
    '/job/add': 'appJobList',
    '/job/detail': 'appJobList',
    '/timekeeping': 'appTimekeeping',
    '/workreport': 'appWorkReport',
    '/machine': 'appMachines',
    //'/photobook': 'appPhotobookLimited',
    '/photobook': 'appPhotobookFull',
    '/sensor': 'appSensor',
  };
  var permissionName = pathMapObject[path];
  if (permissionName == 'appPhotobookFull' || permissionName == 'appPhotobookLimited') {
    permissionName = null;
  }
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (!auth.currentuser())
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            ></Redirect>
          );

        if (permissionName && !showMenuPermission(permissionName)) {
          return (
            <Redirect
              to={{ pathname: "/not-allowed", state: { from: props.location } }}
            ></Redirect>
          );
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    ></Route>
  );
};

export default ProtectedRoute;
